<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    v-bind="$attrs"
  ><path
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
    d="M8.25 15L12 18.75L15.75 15m-7.5-6L12 5.25L15.75 9"
  /></svg>
</template>
