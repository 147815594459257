<template>
  <form
    novalidate
    :class="wrapperClass"
    @submit.prevent
  >
    <div class="flex items-center border border-brand-secondary/20 shadow-lg rounded-r-md rounded-l-md flex-shrink-0" :class="hasError && 'has-error'">
      <Listbox
        v-model="selectedCountry"
        as="div"
      >
        <div class="relative">
          <ListboxButton
            class="pl-6 pb-3 w-[7.5rem] rounded-l-md flex-shrink-0"
            :class="isDark ? 'bg-white/5 border border-white/40 border-r-0' : 'bg-brand-white'"
          >
            <span
              class="select-none block text-left text-sm pt-1.5"
              :class="isDark ? 'text-gray-200' : 'text-brand-black/50'"
            >
              {{ prefixLabel }}
            </span>
            <span
              class="flex items-center"
              :class="isDark ? 'text-gray-200' : 'text-brand-black/80'"
            >
              <span class="flex items-center flex-grow">
                <span>{{ selectedCountry?.flag }}</span>
                <small class="ml-3 block">+{{ selectedCountry?.callingCode }}</small>
              </span>
              <span class="pointer-events-none ml-3 flex items-center">
                <ChevronUpdownIcon class="h-5 w-5 text-brand-alternate-20" aria-hidden="true" />
              </span>
            </span>
          </ListboxButton>

          <ListboxOptions class="min-w-[20rem] absolute z-20 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption
              v-for="country in computedCountries"
              :key="country.prefix"
              v-slot="{ active, selected }"
              as="template"
              :value="country"
            >
              <li :class="[active ? 'bg-brand-black/40 text-white' : '', 'relative cursor-pointer select-none py-2 pl-3 pr-6 text-brand-black']" :title="country.country">
                <div class="flex items-center">
                  {{ country.flag }}
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-2 block truncate']">{{ country.country }}</span>
                  <span :class="[active ? 'text-white' : 'text-brand-secondary']" class="ms-1">+{{ country.callingCode }}</span>
                </div>

                <span v-if="selected" :class="[active ? 'text-white' : 'text-brand-secondary', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                  <CheckIcon class="h-6 w-6" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </div>
      </Listbox>

      <BaseInput
        id="phone"
        v-model="phoneNumberModel"
        v-bind="$attrs"
        :label="label"
        type="tel"
        borderless
        wrapper-class="border-l rounded-r-md"
        :size="size"
        :has-error="hasError"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, computed, watch, useAttrs } from 'vue';
import { getCountries, getCountryCallingCode, parsePhoneNumber, type CountryCode } from 'libphonenumber-js';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import BaseInput from './BaseInput.vue';
import type { InputSizes } from '~/types';
import ChevronUpdownIcon from '../icons/ChevronUpdownIcon.vue';
import CheckIcon from '../icons/CheckIcon.vue';
import { countryCodeToUnicodeFlag, getCountryByIsoCode } from '~/lib/utils';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import { useRuntimeConfig } from '#imports';

const { DEFAULT_LOCALE } = useRuntimeConfig().public;

polyfillCountryFlagEmojis();

interface Props {
  size: InputSizes,
  defaultCountry?: CountryCode
  modelValue?: string
  hasError?: boolean
  wrapperClass?: string
  prefixLabel?: string
  label?: string
}

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(defineProps<Props>(), {
  defaultCountry: undefined,
  modelValue: undefined,
  hasError: false,
  wrapperClass: undefined,
  prefixLabel: 'Prefijo',
  label: undefined,
});

const defaultLocale = computed(() => props.defaultCountry || DEFAULT_LOCALE.toUpperCase() as CountryCode);
const attrs = useAttrs();
const isDark = computed(() => 'dark' in attrs);

interface ComputedCountry {
  prefix: CountryCode
  flag: string
  country?: string
  callingCode: string | number
}

// eslint-disable-next-line func-call-spacing
const emits = defineEmits<{
  (event: 'update:model-value', phoneNumber?: string): void
  (event: 'update', phone?: string): void
}>();

const computedCountries: ComputedCountry[] = getCountries().map(countryCode => ({
  prefix: countryCode,
  flag: countryCodeToUnicodeFlag(countryCode),
  country: getCountryByIsoCode(countryCode),
  callingCode: getCountryCallingCode(countryCode),
}));

const selectedCountry = ref(computedCountries.find(c => c.prefix === defaultLocale.value));
const phoneNumberModel = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:model-value', value);
  },
});

function getParsedNumber (value: string, country?: CountryCode) {
  return parsePhoneNumber(String(value), country || defaultLocale.value);
}

function onInputValueChanged (v: string) {
  try {
    const parsed = getParsedNumber(v, selectedCountry.value?.prefix);
    selectedCountry.value = computedCountries.find(c => c.prefix === parsed.country);
    emits('update:model-value', parsed.number);
    // emits('update', parsed.number);
  } catch (error: any) {
    if (error.name !== 'ParseError2') {
      console.error(error);
    }
  }
}

function onCountryValueChanged (countryValue: ComputedCountry) {
  if (phoneNumberModel.value) {
    try {
      const phoneNumber = getParsedNumber(phoneNumberModel.value, countryValue.prefix);
      if (phoneNumber) {
        const { callingCode } = countryValue;
        if (phoneNumber.countryCallingCode !== callingCode) {
          const newNumberWithCallingCode = `${callingCode}${phoneNumber.nationalNumber}`;
          emits('update:model-value', newNumberWithCallingCode);
          selectedCountry.value = countryValue;
        }
      }
    } catch (error) {
      // NoOp
    }
  }
}

watch(phoneNumberModel, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    onInputValueChanged(newVal as string);
  }
});

watch(selectedCountry, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    onCountryValueChanged(newVal as ComputedCountry);
  }
});
</script>
